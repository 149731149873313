<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Supplemental Branches</h3>

	<p>Items designated as “supplemental branches” in the item editor will appear when browsing the framework in <span v-html="app_name"/>, but will not be included when external systems request the framework CASE data via the <span v-html="link('case_rest_api', 'CASE REST API')"></span> (unless the “extended” version of the API is used).</p>
	<p class="text-center"><img srcset="/docimages/supplemental_branches-1.png 2x" class="k-help-img"></p>
	<p>Supplemental branches can be useful if the framework author wishes to organize some standards in multiple different ways within the framework.</p>
	<ul>
		<li>For example, a set of language development standards might be organizable either by content area or by “language use”, and different populations of educators might best understand the standards when organized one way or another.</li>
		<li>However, we wouldn’t want to have two different <i>copies</i> of the standards, because then each copy would have a different <span v-html="link('identifiers', 'GUID')"></span> (which would cause confusion when, for example, resources are aligned to those GUIDs).</li>
		<li><span v-html="app_name"/> allows the framework author to initially create the standards under one “branch” of the framework “tree”, then create a second branch and add an <span v-html="link('copy_item', '“alias”')"></span> of the standards under this second branch.</li>
		<li>However, many systems that consume CASE data do not know how to handle such aliases: more specifically, they don’t know what to do when they encounter an item with the same GUID in multiple positions in the framework hierarchy.</li>
		<li>This is where the author can designate the second branch as a “supplemental branch”, so that when these other systems consume the framework’s CASE data, they will receive a single copy of each framework organized in the first (non-“supplemental”) hierarchical structure.</li>
	</ul>
	<!-- TODO: add a description of how Satchel does this in the back end, using the isSupplementalItem parameter -->
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	